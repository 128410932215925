import Select from '@veneer/core/dist/scripts/select'
import React, { useEffect, useState, useMemo } from 'react'
import { getCropedAccountName, getCropedResourceId } from './utils/cropText'
import * as S from './styles'
import * as T from './types'

import Button from '@veneer/core/dist/scripts/button'
import RadioButton from '@veneer/core/dist/scripts/radio_button'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import RadioButtons from '@veneer/core/dist/scripts/radio_buttons'
import createId from './utils/createId'

const tenantsRadioLimit = 5
const TenantSelectorPage: T.TenantSelectorPageType = ({
  fullscreen = false,
  tenantHandler,
  finishTenantSelector,
  t,
  navigation
}) => {
  const tenantWrapper = useMemo(
    () => tenantHandler?.getNextUnproccessedTenant(),
    [tenantHandler]
  )
  const ContentWrapper = useMemo(
    () => (fullscreen ? S.Background : React.Fragment),
    [fullscreen]
  )

  const { strategy: tenantStrategy, level } = tenantWrapper

  const tenantId = tenantHandler?.getTenantId(level)

  const [isChangingDomain, setIsChangingDomain] = useState(false)
  const [selectedDomainId, setSelectedDomainId] = useState(tenantId)
  const [tenants, setTenants] = useState([] as T.TenantSelectorData[])
  const [tenantTypes, setTenantTypes] = useState(
    {} as Record<string, T.TenantVisualizationType>
  )

  useEffect(() => {
    ;(async () => {
      const tenantDataList = await tenantStrategy?.getTenantList?.()

      if (tenantDataList.length === 0) {
        const fallbackPath = (navigation as any).createHref({
          pathname: tenantStrategy.getFallback()
        })
        window.location.href = fallbackPath
      }

      const newTenantTypes = {}

      const tenantSelectDataList = tenantDataList?.map(({ name, id, type }) => {
        newTenantTypes[id] = { type, name }
        const label = `${getCropedAccountName(name)} ${getCropedResourceId(id)}`
        return {
          label,
          value: id
        }
      })

      setTenantTypes(newTenantTypes)
      setTenants(tenantSelectDataList || [])
    })()
  }, [tenantStrategy])

  useEffect(() => {
    if (tenants?.length === 1) {
      ;(async () => {
        const currentTenant = tenants?.[0]
        const currentTenantData = tenantTypes?.[currentTenant.value]
        await tenantHandler.setTenant(
          currentTenant?.value,
          level,
          {
            reload: false
          },
          { ...currentTenantData }
        )
        finishTenantSelector()
      })()
    }
    setSelectedDomainId(tenants[0]?.value)
  }, [tenants, tenantHandler, level, finishTenantSelector, tenantTypes])

  const isSelect = (tenants?.length || 0) > tenantsRadioLimit
  const firstItem = tenants.length ? tenants[0]?.value : undefined

  async function onConfirmation() {
    if (selectedDomainId) {
      setIsChangingDomain(true)
      try {
        // FIXME: Exchange token
        await tenantHandler.setTenant(
          selectedDomainId,
          level,
          {
            reload: false
          },
          tenantTypes?.[selectedDomainId]
        )
        finishTenantSelector()
        setIsChangingDomain(false)
      } catch {
        setTimeout(() => {
          onConfirmation()
        }, 1000)
      }
    }
  }

  if (tenants?.length <= 1) {
    return null
  }

  return (
    <ContentWrapper>
      <S.Container
        data-testid={createId('container')}
        appearance="dropShadow"
        content={
          <S.Content isselect={isSelect}>
            <S.TitleWrapper>
              <S.Title data-testid={createId('welcome_back')}>
                {t('nav.tenant-selector.page.title', 'Welcome Back')}
              </S.Title>
              <S.SubTitle>
                {t(
                  'nav.tenant-selector.page.subtitle',
                  'Select an organization to sign in.'
                )}
              </S.SubTitle>
            </S.TitleWrapper>
            <S.FormDiv isselect={isSelect}>
              {isSelect ? (
                <Select
                  disabled={isChangingDomain}
                  options={tenants}
                  clearIcon={false}
                  value={selectedDomainId ? [selectedDomainId] : undefined}
                  onChange={(e) => setSelectedDomainId(e?.value as string)}
                />
              ) : (
                <RadioButtons
                  name="radiobutton_default"
                  defaultValue={firstItem}
                  value={selectedDomainId}
                  onChange={(e, value) => setSelectedDomainId(value)}
                  data-testid={createId('organizationList')}
                >
                  {tenants?.map?.((props, index) => (
                    <RadioButton
                      key={props.value}
                      disabled={isChangingDomain}
                      data-testid={createId('organization_' + index)}
                      {...props}
                    />
                  ))}
                </RadioButtons>
              )}
              <Button
                disabled={!selectedDomainId || isChangingDomain}
                onClick={() => onConfirmation()}
                appearance="primary"
                data-testid={createId('continue_button')}
              >
                {isChangingDomain ? (
                  <ProgressIndicator color="colorGray4" appearance="circular" />
                ) : (
                  t('nav.tenant-selector.page.button', 'Continue')
                )}
              </Button>
            </S.FormDiv>
          </S.Content>
        }
      />
    </ContentWrapper>
  )
}

export default TenantSelectorPage
