import React, { useEffect, useState } from 'react'
import TenantSelector from '../src/index'
import { ShellProps, TenantHandlerType } from '../src/types/shell'
import { MfePropsType } from '../src/types/mfeProps'
import projectNames from '../src/configs/projectNames'
import { TranslatorFunctionType } from '../src/types/localization'
import resources from '../src/assets/locale'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type TenantSelectorProps = {
  finishTenantSelector: () => void
  tenantHandler: TenantHandlerType
  fullscreen: boolean
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  finishTenantSelector,
  fullscreen,
  tenantHandler,
  ...props
}: MfePropsType & TenantSelectorProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>()
  const interfaces = window.Shell.v1

  // Create a translator function by providing the resource files
  useEffect(() => {
    interfaces?.localization
      ?.createTranslatorFunction?.(resources)
      .then((v) => setT(() => v))
  }, [interfaces])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <TenantSelector
        {...{
          ...props,
          ...interfaces,
          t,
          finishTenantSelector,
          tenantHandler,
          fullscreen
        }}
      />
    </section>
  )
}
